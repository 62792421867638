import React, { useState } from "react";
import logo from "./bto-logo.png";
import "./App.css";
import { PasswordResetSuccesfully } from "./components/PasswordResetSuccesfully";
import { FailedToReset } from "./components/FailedToReset";
import { PasswordRequirements } from "./components/PasswordRequirements";

export default function App() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showModalSuccessfull, setShowModalSuccessfull] = useState(false);
  const [showModalFailed, setShowModalFailed] = useState(false);
  const [showModalPasswordRequirements, setShowModalPasswordRequirements] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const BASE_URL = "https://btoqueryfactory.azurewebsites.net/";

  const currentURL = new URL(window.location.href);
  const urlParams = currentURL.searchParams;

  const rawCode = urlParams.get("code");
  const code = rawCode ? rawCode.replace(/ /g, "+") : "";
  const email = urlParams.get("email");

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${BASE_URL}api/Account/ResetPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: newPassword,
          confirmPassword: confirmPassword,
          code: code,
        }),
      });

      console.log("This is the response:", response);

      if (response.ok) {
        setShowModalSuccessfull(true);
        setNewPassword("");
        setConfirmPassword("");
      } else if (response.errors.Password) {
        setShowModalPasswordRequirements(true);
      } else if (response.errors.ConfirmPassword) {
        const error = await response.json();
        console.error("Error:", error);
        setShowModalFailed(true);
      }
    } catch (error) {
      console.error(error.message);
      setShowModalFailed(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-14 w-auto" src={logo} alt="bto-zorg" />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-stone-900">
            Reset your password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleResetPassword}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-stone-700"
              >
                New Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-stone-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F0653C] custom-focus-ring sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
                <div className="text-sm"></div>
              </div>
              <div className="mt-2">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#F0653C] custom-focus-ring sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm custom-focus-ring focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus:ring-[#F0653C] custom-focus-ring"
                style={{
                  backgroundColor: "#F0653C",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#E6512B")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#F0653C")
                }
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModalSuccessfull && <PasswordResetSuccesfully />}
      {showModalFailed && <FailedToReset />}
      {showModalPasswordRequirements && <PasswordRequirements />}
    </>
  );
}
